export const supportsDynamicImport = (): boolean => {
  try {
    new Function('import("")');
    return true;
  } catch (err) {
    return false;
  }
};

export const isTouchDevice = (): boolean => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};
