import { formatCurrency, getSelectedLocale } from '@maersk-global/shared-js';

export interface FormatDataType {
  type: 'number' | 'string';
  options?: Intl.NumberFormatOptions;
}

/**
 * Formats a value using the specified data type and locale.
 * If no locale is specified, the locale from the cookie is used.
 * Uses `formatCurrency` from `@maersk-global/shared-js` if currency is specified in dataType options.
 *
 * @param {string | number} value - The value to format.
 * @param {FormatDataType} dataType - The data type to use for formatting.
 * @param {string} [locale] - The locale to use for formatting.
 * @returns {string} The formatted value.
 */
export const format = (value: string | number, dataType: FormatDataType, locale?: string): string => {
  const cookieLocale = getSelectedLocale(new Intl.NumberFormat().resolvedOptions().locale || 'en-GB');
  const { type, options } = dataType || {};

  if (type === 'number') {
    const currency = (options as Intl.NumberFormatOptions)?.currency;
    if (currency) {
      return `${formatCurrency(+value, currency || 'USD')} ${currency}`;
    }
    return new Intl.NumberFormat(locale || cookieLocale, options).format(+value);
  }

  return value.toString();
};
