import { padStart } from '../index';

export const timeFormatter = (value: string): [string, string, string] => {
  if (!value.includes(':') || value[2] !== ':') {
    value = value.replace(/[^0-9]/g, '');
    value = [value.slice(0, 2), ':', value.slice(2)].join('');
  }

  const times = value.split(':');
  let hour = +times[0];
  let minute = +times[1];

  if (hour > 23) {
    hour = 23;
  }

  if (hour < 0) {
    hour = 0;
  }

  if (minute > 59) {
    minute = 59;
  }

  if (minute < 0) {
    minute = 0;
  }

  const formattedHour = padStart(hour);
  const formattedMinute = padStart(minute);

  return [`${formattedHour}:${formattedMinute}`, formattedHour, formattedMinute];
};
