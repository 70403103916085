/**
 * @license
 * Copyright 2018 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */
// Style preference for leading underscores.
// tslint:disable:strip-private-property-underscore

export function addHasRemoveClass(element) {
  return {
    addClass: (className) => {
      element.classList.add(className);
    },
    removeClass: (className) => {
      element.classList.remove(className);
    },
    hasClass: (className) => element.classList.contains(className),
  };
}
