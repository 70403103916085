import { CallBackType } from './callback';

export const debounce = (context: unknown, callback: CallBackType, delay = 0): CallBackType => {
  let timeoutId: number;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback.apply(context, args);
    }, delay);
  };
};
